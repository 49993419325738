import * as http from "utils/http";

enum Type {
  ERROR = "error",
  NOTICE = "notice",
  SUCCESS = "success",
  WARNING = "warning",
}
const FLASH_PARENT_SELECTOR = ".flash";

async function notify(text: string, type: Type) {
  const response = await http.httpGet("/frontend/flash", { text, type });
  document.querySelector(FLASH_PARENT_SELECTOR).innerHTML = await response.text();
}

export function alert(text: string) {
  notify(text, Type.ERROR);
}

export function notice(text: string) {
  notify(text, Type.NOTICE);
}

export function success(text: string) {
  notify(text, Type.SUCCESS);
}

export function warning(text: string) {
  notify(text, Type.WARNING);
}
