import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["topSection", "topTable", "bottomTable"];
  static values = {
    minHeight: { type: Number, default: 100 },
    pageId: { type: String },
  };

  declare readonly topTableTarget: HTMLElement;
  declare readonly bottomTableTarget: HTMLElement;
  declare readonly minHeightValue: number;
  declare readonly pageIdValue: string;
  declare readonly hasBottomTableTarget: boolean;

  private isDragging = false;
  private currentY = 0;
  private minimumTableBodyHeight = 200;
  private headerSize = 300;
  private minimumTableHeight = 600;
  private resizeObserver: ResizeObserver | null = null;
  private initialHeightsSet = false;

  private get storageKeys() {
    const prefix = this.pageIdValue;
    return {
      screenHeight: `${prefix}_last_screen_height`,
      topHeight: `${prefix}_top_table_height`,
      bottomHeight: `${prefix}_bottom_table_height`,
    };
  }

  connect() {
    this.bindMethods();
    requestAnimationFrame(() => {
      this.initializeHeights();
      this.setupResizeHandler();
      this.setupResizeObserver();
    });
  }

  disconnect() {
    window.removeEventListener("resize", this.handleResize);
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  bottomTableTargetConnected() {
    const topTable = document.querySelector('[data-common--resizable-tables-target="topTable"]') as HTMLElement;
    const windowHeight = window.innerHeight;
    const topOffset = topTable.offsetHeight + this.headerSize;
    const availableHeight = windowHeight - topOffset;
    if (availableHeight >= this.minHeightValue) {
      this.bottomTableTarget.style.height = `${availableHeight}px`;
    }
  }

  dragStart(event: MouseEvent) {
    this.isDragging = true;
    this.currentY = event.clientY;
    document.addEventListener("mousemove", this.drag);
    document.addEventListener("mouseup", this.dragEnd);
  }

  private bindMethods() {
    this.dragStart = this.dragStart.bind(this);
    this.dragEnd = this.dragEnd.bind(this);
    this.drag = this.drag.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  private setupResizeHandler() {
    if (this.getLastScreenHeight() !== window.innerHeight) {
      this.handleResize();
    }
    window.addEventListener("resize", this.handleResize);
  }

  private getLastScreenHeight(): number {
    const height = localStorage.getItem(this.storageKeys.screenHeight);
    return height ? Number(height) : window.innerHeight;
  }

  private handleResize() {
    const currentTop = this.topTableTarget.offsetHeight;
    const currentBottom = this.hasBottomTableTarget ? this.bottomTableTarget.offsetHeight : 0;

    const totalHeight = currentTop + currentBottom;
    const topRatio = currentTop / totalHeight;

    const newTotalHeight = window.innerHeight - 300;
    const newTopHeight = Math.max(this.minHeightValue, Math.floor(newTotalHeight * topRatio));
    const newBottomHeight = Math.max(this.minHeightValue, newTotalHeight - newTopHeight);

    this.setGridHeights(String(newTopHeight), String(newBottomHeight));
    this.saveGridHeights();
    localStorage.setItem(this.storageKeys.screenHeight, String(window.innerHeight));
  }

  private initializeHeights() {
    const storedHeights = this.getStoredHeights();
    if (storedHeights) {
      const topHeight = Number(storedHeights.top);
      const bottomHeight = Number(storedHeights.bottom);

      if (this.areHeightsValid(topHeight, bottomHeight)) {
        this.setGridHeights(storedHeights.top, storedHeights.bottom);
        this.initialHeightsSet = true;
      }
    }
  }

  private getStoredHeights() {
    const topHeight = localStorage.getItem(this.storageKeys.topHeight);
    const bottomHeight = localStorage.getItem(this.storageKeys.bottomHeight);
    return topHeight && bottomHeight ? { top: topHeight, bottom: bottomHeight } : null;
  }

  private setGridHeights(topHeight: string, bottomHeight: string) {
    this.topTableTarget.style.setProperty("height", `${topHeight}px`);
    if (this.hasBottomTableTarget) {
      this.bottomTableTarget.style.setProperty("height", `${bottomHeight}px`);
    }
  }

  private saveGridHeights() {
    localStorage.setItem(this.storageKeys.topHeight, String(this.topTableTarget.offsetHeight));
    if (this.hasBottomTableTarget) {
      localStorage.setItem(this.storageKeys.bottomHeight, String(this.bottomTableTarget.offsetHeight));
    }
  }

  private dragEnd() {
    this.isDragging = false;
    document.removeEventListener("mousemove", this.drag);
    document.removeEventListener("mouseup", this.dragEnd);
    this.saveGridHeights();
  }

  private drag(event: MouseEvent) {
    if (!this.isDragging) return;

    const deltaY = event.clientY - this.currentY;
    const isMovingDown = deltaY > 0;
    const bottomTable = document.querySelector('[data-common--resizable-tables-target="bottomTable"]') as HTMLElement;
    const newTopHeight = this.topTableTarget.offsetHeight + deltaY;

    if (bottomTable instanceof HTMLElement) {
      const newBottomHeight = bottomTable.offsetHeight - deltaY;
      const canMoveDown = isMovingDown && this.getVisibleHeight(bottomTable) > this.minimumTableBodyHeight;
      const canMoveUp = !isMovingDown && this.getVisibleHeight(this.topTableTarget) > this.minimumTableBodyHeight;

      if (canMoveDown || canMoveUp) {
        this.setGridHeights(String(newTopHeight), String(newBottomHeight));
        this.currentY = event.clientY;
      }
      return;
    }

    const canMoveDown =
      isMovingDown && this.getVisibleHeight(this.topTableTarget) + this.minimumTableHeight < window.innerHeight;
    const canMoveUp = !isMovingDown && this.getVisibleHeight(this.topTableTarget) > this.minimumTableBodyHeight;

    if (canMoveDown || canMoveUp) {
      this.setGridHeights(String(newTopHeight), "0");
      this.currentY = event.clientY;
    }
  }

  private getVisibleHeight(element) {
    const rect = element.getBoundingClientRect();
    return Math.max(0, Math.min(rect.bottom, window.innerHeight) - Math.max(rect.top, 0));
  }

  private areHeightsValid(topHeight: number, bottomHeight?: number): boolean {
    if (bottomHeight === undefined) {
      return topHeight >= this.minHeightValue;
    }
    return topHeight >= this.minHeightValue && bottomHeight >= this.minHeightValue;
  }

  private setupResizeObserver() {
    const topTable = document.querySelector('[data-common--resizable-tables-target="topSection"]') as HTMLElement;

    if (this.hasBottomTableTarget) {
      this.resizeObserver = new ResizeObserver((entries) => {
        if (this.isDragging) return;
        for (const entry of entries) {
          const windowHeight = window.innerHeight;
          const topOffset = topTable.offsetHeight + this.headerSize;
          const availableHeight = windowHeight - topOffset;
          if (availableHeight >= this.minHeightValue) {
            this.bottomTableTarget.style.height = `${availableHeight}px`;
          }
        }
      });
      this.resizeObserver.observe(topTable);
    }
  }
}
