import { Controller } from "@hotwired/stimulus";
import * as AgGrid from "ag-grid-community";
import * as columns from "../../grid/columns";
import * as datasource from "../../grid/datasource";
import * as dom from "../../utils/dom";
import * as events from "../../utils/events";
import * as gridUtils from "../../grid/utils";
import * as http from "../../utils/http";
import * as rowActions from "./row_actions";
import { ColumnsState } from "grid/columns_state";

import {
  ACTIONS_COL_DEF,
  AMOUNT_COL_DEF,
  AMOUNT_FILTER_COL_DEF,
  DATE_FILTER_COL_DEF,
  DEFAULT_COL_DEF,
  FILTER_TRANSLATIONS,
  STATUS_COL_DEF,
  TEXT_FILTER_COL_DEF,
} from "../../grid/columns";

import {
  EDITABLE_ACCOUNT_NUMBER_COL_DEF,
  EDITABLE_AMOUNT_COL_DEF,
  EDITABLE_DATE_COL_DEF,
  EDITABLE_TEXT_COL_DEF,
} from "./columns";

enum Status {
  OPEN = "OPEN",
}

async function handleDiscard(params) {
  const response = await http.httpDelete(params.data.discard_path);
  const data = await response.json();

  if (response.ok) {
    params.api.purgeInfiniteCache();
  } else {
    alert(data.error);
  }
}

function handleEdit(params, defaultPaymentMethod) {
  params.data.payment_amount ||= params.data.due_amount;
  params.data.payment_date = columns.formatDateToUSFormat(params.data.payment_date);
  params.data.payment_method ||= JSON.parse(defaultPaymentMethod);
  params.api.startEditingCell({ rowIndex: params.node.rowIndex, colKey: "payment_method" });
  // rowActions.show(params.event.target.closest('.ag-cell'), true)
  rowActions.show(gridUtils.actionsCell(params), true);
}

async function handleProvision(params) {
  params.api.setGridOption("pinnedTopRowData", []);
  params.api.stopEditing();
  const body = {
    allocation: {
      account_number: params.data.account_number,
      comment: params.data.comment,
      id: params.data.id,
      payment_amount: params.data.payment_amount,
      payment_date: params.data.payment_date,
      payment_method: params.data.payment_method,
    },
  };
  const response = await http.httpPost(params.data.provision_path, body);
  const data = await response.json();

  if (response.ok) {
    params.api.purgeInfiniteCache();
  } else {
    alert(data.error);
  }
}

async function addAllocation(api: AgGrid.GridApi, url: string) {
  const response = await http.httpGet(url);
  const data = await response.json();

  if (response.ok) {
    if (data.payment_date) {
      data.payment_date = columns.formatDateToUSFormat(data.payment_date);
    }

    api.setGridOption("pinnedTopRowData", [data]);
    api.startEditingCell({ rowIndex: 0, rowPinned: "top", colKey: "account_number" });
    rowActions.show(document.querySelector('.ag-row-pinned div[col-id="actions"]'), true);
  } else {
    alert(data.error);
  }
}

interface DuePayment {
  comment: string;
  customer_name: string;
  due_amount: number;
  due_date: string;
  payment_amount: number;
  payment_date: string;
  payment_method: string;
  status: string;
  status_orig: string;
  user_name: string;
}

interface CtrlCallbacks {
  subscribeOnAddAllocation(callback: () => void): void;
  subscribeOnSearchSubmit(callback: () => void): void;
  subscribeOnResetColumnWidths(callback: () => void): void;
}

function buildGrid($grid: HTMLElement, dataCallbacks: datasource.DatasourceCallbacks, ctrlCallbacks: CtrlCallbacks) {
  const { i18nJson, newAllocationUrl, paginationPageSize, paymentMethodsJson, statusesJson, defaultPaymentMethod } =
    $grid.dataset;

  const colDefs: AgGrid.ColDef[] = [
    { field: "status", ...STATUS_COL_DEF, sortable: true, ...columns.buildEnumFilterColDef(JSON.parse(statusesJson)) },
    { field: "due_date", ...DEFAULT_COL_DEF, ...DATE_FILTER_COL_DEF, sortable: true, sort: "desc" },
    { field: "account_number", ...EDITABLE_ACCOUNT_NUMBER_COL_DEF, ...TEXT_FILTER_COL_DEF, sortable: true },
    { field: "due_amount", ...AMOUNT_COL_DEF, ...AMOUNT_FILTER_COL_DEF, sortable: true },
    {
      field: "payment_method",
      ...DEFAULT_COL_DEF,
      sortable: true,
      ...columns.buildEnumEditableColDef(JSON.parse(paymentMethodsJson)),
      ...columns.buildEnumFilterColDef(JSON.parse(paymentMethodsJson)),
    },
    { field: "payment_date", ...EDITABLE_DATE_COL_DEF, ...DATE_FILTER_COL_DEF, sortable: true },
    { field: "payment_amount", ...EDITABLE_AMOUNT_COL_DEF, ...AMOUNT_FILTER_COL_DEF, sortable: true },
    {
      field: "customer_name",
      ...DEFAULT_COL_DEF,
      ...TEXT_FILTER_COL_DEF,
      tooltipField: "customer_name",
      sortable: true,
    },
    { field: "user_name", ...DEFAULT_COL_DEF, sortable: false },
    { field: "comment", ...EDITABLE_TEXT_COL_DEF, tooltipField: "comment", sortable: false },
    {
      field: "actions",
      ...ACTIONS_COL_DEF,
      onCellClicked(params) {
        rowActions.onActionCellClicked(params, function (action) {
          switch (action) {
            case rowActions.Action.CANCEL:
              return rowActions.handleCancel(params);
            case rowActions.Action.DISCARD:
              return handleDiscard(params);
            case rowActions.Action.EDIT:
              return handleEdit(params, defaultPaymentMethod);
            case rowActions.Action.PROVISION:
              return handleProvision(params);
          }
        });
      },
    },
  ];

  const dataSource = new datasource.Datasource({ callbacks: dataCallbacks, limit: paginationPageSize });

  const options: AgGrid.GridOptions<DuePayment> = {
    ...gridUtils.DEFAULT_GRID_OPTIONS,
    // override defaults
    defaultColDef: {
      ...(gridUtils.DEFAULT_GRID_OPTIONS.defaultColDef as AgGrid.ColDef<DuePayment, any>),
      suppressKeyboardEvent(params) {
        return rowActions.suppressKeyboardEvent(params, {
          handleEdit,
          handleProvision,
        });
      },
    },
    // animateRows: true,
    cacheBlockSize: parseInt(paginationPageSize),
    columnDefs: gridUtils.buildColumnDefs(colDefs, { i18n: JSON.parse(i18nJson) }),
    localeText: {
      ...FILTER_TRANSLATIONS,
    },
    datasource: dataSource,
    // editing
    editType: "fullRow",
    suppressClickEdit: true,
    // suppressRowClickSelection: true,
    // events
    onCellEditingStopped(params) {
      if (gridUtils.isRowPinned(params)) {
        gridUtils.restartEditingPinnedRow(params, gridApi);
      } else {
        params.node.setDataValue(params.column, params.data.status_orig == Status.OPEN ? null : params.oldValue);
      }
    },
    onRowEditingStopped(params) {
      if (gridUtils.isRowPinned(params)) {
        gridUtils.restartEditingPinnedRow(params, gridApi);
      } else {
        rowActions.hide(gridUtils.actionsCell(params));
      }
    },
    onColumnResized: (event: AgGrid.ColumnResizedEvent) => {
      columnsState.onResize(event);
    },
    onCellMouseOver: rowActions.GRID_CALLBACKS.onCellMouseOver,
    onCellMouseOut: rowActions.GRID_CALLBACKS.onCellMouseOut,
    onRowDoubleClicked(params) {
      if (!gridUtils.isEditing(params)) {
        handleEdit(params, defaultPaymentMethod);
      }
    },
  };
  const gridApi = AgGrid.createGrid($grid, options);
  dataSource.gridApi = gridApi;
  const columnsState = new ColumnsState(gridApi, "allocations_due_payments");
  columnsState.initialize();

  ctrlCallbacks.subscribeOnAddAllocation(() => addAllocation(gridApi, newAllocationUrl));
  ctrlCallbacks.subscribeOnResetColumnWidths(() => {
    columnsState.reset();
  });
  ctrlCallbacks.subscribeOnSearchSubmit(() => gridApi.onFilterChanged());
  return gridApi;
}

export default class extends Controller {
  static targets = ["grid", "searchInput", "totalPaymentAmount"];

  declare readonly gridTarget: HTMLInputElement;
  declare readonly hasGridTarget: boolean;
  declare readonly hasSearchInputTarget: boolean;
  declare readonly totalPaymentAmountTarget: HTMLInputElement;

  gridApi: AgGrid.GridApi;

  connect() {
    const ctrl = this;

    if (this.hasGridTarget) {
      const $addAllocationBtn: HTMLElement = document.querySelector(".add-allocation");
      const $resetGridBtn: HTMLElement = this.element.querySelector(".reset-grid");
      const $createBookingBtn: HTMLElement = document.querySelector(".create-booking");
      const $searchInput: HTMLInputElement = document.querySelector(".search-input");

      this.gridApi = buildGrid(
        this.gridTarget,
        {
          getSearchInput() {
            return $searchInput.value;
          },
          onDataReceivedSuccess(response: any) {
            if (response.currency_code != undefined) {
              ctrl.setTotalAmounts(response.payment_amount, response.currency_code);
            }
            if ($createBookingBtn && response.provisional_count != undefined) {
              (response.provisional_count > 0 ? dom.showElement : dom.hideElement)($createBookingBtn);
            }
          },
        },
        {
          subscribeOnAddAllocation(callback) {
            if ($addAllocationBtn) {
              $addAllocationBtn.onclick = callback;
            }
          },
          subscribeOnSearchSubmit(callback: () => void) {
            events.subscribeOnEnter($searchInput, callback);
          },
          subscribeOnResetColumnWidths(callback: () => void) {
            $resetGridBtn.addEventListener("click", callback);
          },
        },
      );
    }
  }

  disconnect() {
    this.gridApi?.destroy();
  }

  // custom

  setTotalAmounts(paymentAmount: number, currencyCode: string) {
    this.totalPaymentAmountTarget.innerText = columns.formatAmount(paymentAmount, currencyCode);
  }
}
