import * as AgGrid from "ag-grid-community";
import { TIME_ZONE } from "settings";

// DISPLAY --------------------------------------------------------------------
export const DISABLED_STYLES = {
  ["color"]: "#9E9E9E",
  ["background-color"]: "#F2F2F2",
};

const DEFAULT_FILTER_OPTIONS = {
  filterOptions: ["inRange", "lessThanOrEqual", "greaterThanOrEqual"],
  inRangeInclusive: true,
};

export const DEFAULT_COL_DEF: AgGrid.ColDef = {
  cellClass: ["font-sans"],
  cellStyle(params): AgGrid.CellStyle {
    let styles = {
      // height: '92%'
    };
    // if (params.data && !isEditable(params))
    if (params.data && params.data.hasOwnProperty("disabled") && params.data.disabled) {
      styles = { ...styles, ...DISABLED_STYLES };
    }
    return styles;
  },
};

export const ACTIONS_COL_DEF: AgGrid.ColDef = {
  cellClass: ["ag-right-aligned-cell", ...(DEFAULT_COL_DEF.cellClass as string[])],
  cellRenderer: "defaultCellRenderer",
  resizable: false,
  sortable: false,
};

export const AMOUNT_COL_DEF: AgGrid.ColDef = {
  ...DEFAULT_COL_DEF,
  cellClass: ["ag-left-aligned-cell", ...(DEFAULT_COL_DEF.cellClass as string[])],
  cellDataType: "number",
  cellRenderer(params: AgGrid.ICellRendererParams) {
    if (params.data && params.value) {
      return formatAmount(params.value, params.data.currency_code);
    }
  },
};

export const DATE_COL_DEF: AgGrid.ColDef = {
  ...DEFAULT_COL_DEF,
  cellDataType: "dateString",
};

export const NUMBER_COL_DEF: AgGrid.ColDef = {
  ...DEFAULT_COL_DEF,
  cellDataType: "number",
};

export const STATUS_COL_DEF: AgGrid.ColDef = {
  ...DEFAULT_COL_DEF,
  cellRenderer: "defaultCellRenderer",
};

export function buildEnumColDef(refData: Record<string, string>): AgGrid.ColDef {
  return {
    cellRenderer(params: AgGrid.ICellRendererParams) {
      return refData[params.value];
    },
    refData,
  };
}

// EDIT -----------------------------------------------------------------------
// https://www.ag-grid.com/javascript-data-grid/reference-data/#using-the-refdata-property
function editable(params: AgGrid.EditableCallbackParams<any, any>) {
  if (params.data.hasOwnProperty("editable")) {
    return params.data.editable;
  } else {
    return true;
  }
}

export function buildEnumEditableColDef(refData: Record<string, string>): AgGrid.ColDef {
  return {
    ...DEFAULT_COL_DEF,
    ...buildEnumColDef(refData),
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: Object.keys(refData),
      useFormatter: true,
    },
    editable,
  };
}

// FILTERS --------------------------------------------------------------------

export const FILTER_TRANSLATIONS = {
  empty: " ",
};

const DEFAULT_FILTER_PARAMS = {
  buttons: ["reset", "apply"],
  closeOnApply: true,
  maxNumConditions: 1,
};

export const AMOUNT_FILTER_COL_DEF: AgGrid.ColDef = {
  filter: "agNumberColumnFilter",
  filterParams: {
    ...DEFAULT_FILTER_PARAMS,
    ...DEFAULT_FILTER_OPTIONS,
  },
};
export const DATE_FILTER_COL_DEF: AgGrid.ColDef = {
  filter: "agDateColumnFilter",
  filterParams: {
    ...DEFAULT_FILTER_PARAMS,
    ...DEFAULT_FILTER_OPTIONS,
    comparator(filterLocalDateAtMidnight: Date, cellValue: string) {
      if (cellValue == null) {
        return 0;
      }
      const cellDate = parseDateFromUS(cellValue);

      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }

      return 0;
    },
    maxValidDate: formatDate(dateInTimeZone(new Date())),
  },
};
export const NUMBER_FILTER_COL_DEF: AgGrid.ColDef = {
  filter: "agNumberColumnFilter",
  filterParams: {
    ...DEFAULT_FILTER_PARAMS,
    ...DEFAULT_FILTER_OPTIONS,
  },
};
export const TEXT_FILTER_COL_DEF: AgGrid.ColDef = {
  filter: "agTextColumnFilter",
  filterParams: {
    ...DEFAULT_FILTER_PARAMS,
    filterOptions: ["contains"],
  },
};

export function buildEnumFilterColDef(valuesMap): AgGrid.ColDef {
  const entries = Object.entries(valuesMap);
  return {
    filter: "agTextColumnFilter",
    filterParams: {
      ...DEFAULT_FILTER_PARAMS,
      filterOptions: [
        "empty",
        ...entries.map(([displayKey, displayName]) => {
          return {
            displayKey,
            displayName,
            predicate(_, cellValue) {
              return displayKey == cellValue;
            },
            numberOfInputs: 0,
          } as AgGrid.IFilterOptionDef;
        }),
      ],
    },
  };
}

function isEditable(params: AgGrid.CellClassParams) {
  const { editable } = params.colDef;

  if (editable instanceof Function) {
    return editable(params);
  }

  return !!editable;
}

// // UTILS ----------------------------------------------------------------------
export function formatAmount(amount: number, currencyCode?: string) {
  const options: Intl.NumberFormatOptions = {
    minimumFractionDigits: 2,
  };
  if (currencyCode) {
    options.currency = currencyCode;
    options.style = "currency";
  }
  return new Intl.NumberFormat("en-EN", options).format(amount);
}

export function formatDate(date: Date) {
  return date.toISOString().slice(0, 10);
}

export function addDays(date: Date, days: number) {
  date.setDate(date.getDate() + days);
  return date;
}

export function parseDateFromUS(date: string) {
  const [month, day, year] = date.split("/").map(Number);
  return new Date(year, month - 1, day);
}

// TODO: move building a blank date outside
export function formatDateToUSFormat(date) {
  if (!date) {
    date = dateInTimeZone(new Date());
  } else if (typeof date === "string") {
    if (/^\d{2}\/\d{2}\/\d{4}$/.test(date)) {
      return date;
    }
    const [year, month, day] = date.split("-").map(Number);
    date = new Date(year, month - 1, day);
  }

  return `${(date.getMonth() + 1).toString().padStart(2, "0")}/${date.getDate().toString().padStart(2, "0")}/${date.getFullYear()}`;
}

export function dateInTimeZone(date: Date) {
  return new Date(date.toLocaleString("en-US", { timeZone: TIME_ZONE }));
}

function isWeekend(date: Date) {
  return date.getDay() === 0 || date.getDay() === 6;
}

function isNoRunDay(date: Date, noRunDays: string[]) {
  return noRunDays.includes(formatDate(date));
}

export function isRunDay(date: Date, noRunDays: string[]) {
  if (isWeekend(date)) {
    return false;
  }

  if (isNoRunDay(date, noRunDays)) {
    return false;
  }

  return true;
}
