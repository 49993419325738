import { Controller } from "@hotwired/stimulus";
import * as AgGrid from "ag-grid-community";
import * as columns from "../../grid/columns";
import * as datasource from "../../grid/datasource";
import * as dom from "../../utils/dom";
import * as events from "../../utils/events";
import * as gridUtils from "../../grid/utils";
import * as http from "../../utils/http";
import * as rowActions from "./row_actions";
import { ColumnsState } from "grid/columns_state";

import {
  ACTIONS_COL_DEF,
  AMOUNT_COL_DEF,
  AMOUNT_FILTER_COL_DEF,
  DATE_FILTER_COL_DEF,
  DEFAULT_COL_DEF,
  TEXT_FILTER_COL_DEF,
  STATUS_COL_DEF,
} from "../../grid/columns";

async function handleDiscard(params) {
  const response = await http.httpPost(params.data.discard_return_path);
  const data = await response.json();

  if (response.ok) {
    params.api.purgeInfiniteCache();
  } else {
    alert(data.error);
  }
}

function handleEdit(params) {
  // params.data.payment_method ||= DEFAULT_PAYMENT_METHOD
  params.api.startEditingCell({ rowIndex: params.node.rowIndex, colKey: "payment_method" });
  // rowActions.show(params.event.target.closest('.ag-cell'), true)
  rowActions.show(gridUtils.actionsCell(params), true);
}

async function handleProvision(params) {
  params.api.stopEditing();
  const body = {
    allocation: {
      return_code: params.data.return_code,
    },
  };
  const response = await http.httpPost(params.data.provision_return_path, body);
  const data = await response.json();

  if (response.ok) {
    params.api.purgeInfiniteCache();
  } else {
    alert(data.error);
  }
}

interface MadePayment {
  comment: string;
  customer_name: string;
  due_amount: number;
  due_date: string;
  payment_amount: number;
  payment_date: string;
  payment_method: string;
  reference_number: string;
  transmission_date: string;
  return_code: string;
  status: string;
  status_orig: string;
  user_name: string;
}

interface CtrlCallbacks {
  subscribeOnSearchSubmit(callback: () => void): void;
  subscribeOnResetColumnWidths(callback: () => void): void;
}

function buildGrid($grid: HTMLElement, dataCallbacks: datasource.DatasourceCallbacks, ctrlCallbacks: CtrlCallbacks) {
  const { i18nJson, paginationPageSize, paymentMethodsJson, returnCodesJson, statusesJson } = $grid.dataset;
  const returnCodes = JSON.parse(returnCodesJson);

  const colDefs: AgGrid.ColDef[] = [
    { field: "status", ...STATUS_COL_DEF, sortable: true, ...columns.buildEnumFilterColDef(JSON.parse(statusesJson)) },
    { field: "due_date", ...DEFAULT_COL_DEF, ...DATE_FILTER_COL_DEF, sortable: true },
    { field: "account_number", ...DEFAULT_COL_DEF, ...TEXT_FILTER_COL_DEF, sortable: true },
    { field: "due_amount", ...AMOUNT_COL_DEF, ...AMOUNT_FILTER_COL_DEF, sortable: true },
    {
      field: "payment_method",
      ...DEFAULT_COL_DEF,
      sortable: true,
      ...columns.buildEnumColDef(JSON.parse(paymentMethodsJson)),
      ...columns.buildEnumFilterColDef(JSON.parse(paymentMethodsJson)),
    },
    { field: "payment_date", ...DEFAULT_COL_DEF, ...DATE_FILTER_COL_DEF, sortable: true },
    { field: "payment_amount", ...AMOUNT_COL_DEF, ...AMOUNT_FILTER_COL_DEF, sortable: true },
    { field: "reference_number", ...DEFAULT_COL_DEF, ...TEXT_FILTER_COL_DEF, sortable: true },
    { field: "transmission_date", ...DEFAULT_COL_DEF, ...DATE_FILTER_COL_DEF, sortable: true, sort: "desc" },
    { field: "customer_name", ...DEFAULT_COL_DEF, ...TEXT_FILTER_COL_DEF, sortable: true },
    { field: "user_name", ...DEFAULT_COL_DEF, sortable: false },
    { field: "comment", ...DEFAULT_COL_DEF, sortable: false },
    {
      field: "return_code",
      ...DEFAULT_COL_DEF,
      tooltipValueGetter: (params) => returnCodes[params.value],
      sortable: true,
      ...columns.buildEnumEditableColDef(returnCodes),
      ...columns.buildEnumFilterColDef(returnCodes),
    },
    {
      field: "actions",
      ...ACTIONS_COL_DEF,
      onCellClicked(params) {
        rowActions.onActionCellClicked(params, function (action) {
          switch (action) {
            case rowActions.Action.DISCARD:
              return handleDiscard(params);
            case rowActions.Action.EDIT:
              return handleEdit(params);
            case rowActions.Action.PROVISION:
              return handleProvision(params);
          }
        });
      },
    },
  ];
  const dataSource = new datasource.Datasource({ limit: paginationPageSize, callbacks: dataCallbacks });

  const options: AgGrid.GridOptions<MadePayment> = {
    ...gridUtils.DEFAULT_GRID_OPTIONS,
    // override defaults
    defaultColDef: {
      ...(gridUtils.DEFAULT_GRID_OPTIONS.defaultColDef as AgGrid.ColDef<MadePayment, any>),
      suppressKeyboardEvent(params) {
        return rowActions.suppressKeyboardEvent(params, {
          handleEdit,
          handleProvision,
        });
      },
    },
    editType: "fullRow",
    cacheBlockSize: parseInt(paginationPageSize),
    columnDefs: gridUtils.buildColumnDefs(colDefs, { i18n: JSON.parse(i18nJson) }),
    datasource: dataSource,
    // events
    onCellEditingStopped(params) {
      params.node.setDataValue(params.column, params.oldValue);
    },
    onCellMouseOver: rowActions.GRID_CALLBACKS.onCellMouseOver,
    onCellMouseOut: rowActions.GRID_CALLBACKS.onCellMouseOut,
    onColumnResized: (event: AgGrid.ColumnResizedEvent) => {
      columnsState.onResize(event);
    },
    onRowDoubleClicked(params) {
      if (!gridUtils.isEditing(params)) {
        handleEdit(params);
      }
    },
    onRowEditingStopped: rowActions.GRID_CALLBACKS.onRowEditingStopped,
  };
  const gridApi = AgGrid.createGrid($grid, options);
  dataSource.gridApi = gridApi;
  const columnsState = new ColumnsState(gridApi, "allocations_made_payments");
  columnsState.initialize();

  ctrlCallbacks.subscribeOnResetColumnWidths(() => {
    columnsState.reset();
  });
  ctrlCallbacks.subscribeOnSearchSubmit(() => gridApi.onFilterChanged());
  return gridApi;
}

export default class extends Controller {
  static targets = ["grid", "searchInput", "totalProvisionalReturnAmount"];

  declare readonly gridTarget: HTMLInputElement;
  declare readonly hasGridTarget: boolean;
  declare readonly hasSearchInputTarget: boolean;
  declare readonly totalProvisionalReturnAmountTarget: HTMLInputElement;

  gridApi: AgGrid.GridApi;

  connect() {
    const ctrl = this;

    if (this.hasGridTarget) {
      const $resetGridBtn: HTMLElement = this.element.querySelector(".reset-grid");
      const $createBookingBtn: HTMLElement = document.querySelector(".create-booking");
      const $searchInput: HTMLInputElement = document.querySelector(".search-input");

      this.gridApi = buildGrid(
        this.gridTarget,
        {
          getSearchInput() {
            return $searchInput.value;
          },
          onDataReceivedSuccess(response: any) {
            if (response.currency_code != undefined) {
              ctrl.setTotalAmounts(response.provisional_return_amount, response.currency_code);
            }
            if ($createBookingBtn && response.provisional_count != undefined) {
              (response.provisional_count > 0 ? dom.showElement : dom.hideElement)($createBookingBtn);
            }
          },
        },
        {
          subscribeOnSearchSubmit(callback: () => void) {
            events.subscribeOnEnter($searchInput, callback);
          },
          subscribeOnResetColumnWidths(callback: () => void) {
            $resetGridBtn.addEventListener("click", callback);
          },
        },
      );
    }
  }

  disconnect() {
    this.gridApi?.destroy();
  }

  // custom

  setTotalAmounts(provisionalReturnAmount: number, currencyCode: string) {
    this.totalProvisionalReturnAmountTarget.innerText = columns.formatAmount(provisionalReturnAmount, currencyCode);
  }
}
