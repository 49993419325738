const CREDENTIALS = "same-origin";

const JSON_HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export function buildLocalUrl(path: string, params: Object) {
  var url = new URL(path, window.location.origin);
  if (params) {
    Object.entries(params).forEach(([name, value]) => url.searchParams.set(name, value.toString()));
  }
  return url;
}

export function httpDelete(url: string, params = {}) {
  return fetch(url + new URLSearchParams(params), {
    credentials: CREDENTIALS,
    headers: {
      ...csrfHeaders(),
      ...JSON_HEADERS,
    },
    method: "DELETE",
  });
}

export function httpGet(url: string, params = null) {
  return fetch(buildLocalUrl(url, params), {
    credentials: CREDENTIALS,
    headers: JSON_HEADERS,
    method: "GET",
  });
}

export function httpPatch(url: string, body = {}) {
  return fetch(url, {
    body: JSON.stringify(body),
    credentials: CREDENTIALS,
    headers: {
      ...csrfHeaders(),
      ...JSON_HEADERS,
    },
    method: "PATCH",
  });
}

export function httpPost(url: string, body = {}) {
  return fetch(url, {
    body: JSON.stringify(body),
    credentials: CREDENTIALS,
    headers: {
      ...csrfHeaders(),
      ...JSON_HEADERS,
    },
    method: "POST",
  });
}

function csrfHeaders() {
  return {
    "X-CSRF-Token": (document.querySelector('meta[name="csrf-token"]') as HTMLMetaElement).content,
  };
}
