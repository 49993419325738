import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static classes = ["error"];
  static targets = ["error", "input", "label"];

  declare readonly errorClass: string;
  declare readonly errorTarget: HTMLDivElement;
  declare readonly inputTarget: HTMLInputElement;
  declare readonly labelTarget: HTMLLabelElement;

  connect() {
    this.inputTarget.oninput = this.resetError.bind(this);
  }

  showError(message: string) {
    this.element.classList.add(this.errorClass);
    this.errorTarget.innerHTML = message;
    this.labelTarget.classList.add("text-alert-500");
  }

  private resetError() {
    this.element.classList.remove(this.errorClass);
    this.errorTarget.innerHTML = "";
    this.labelTarget.classList.remove("text-alert-500");
  }
}
